var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Datos generales")]),_c('span',{staticClass:"font-italic ml-3"},[_vm._v("* Campos requeridos")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre *","placeholder":"Nombre","dense":"","error-messages":errors[0],"append-icon":"mdi-account-tie"},model:{value:(_vm.broker.name),callback:function ($$v) {_vm.$set(_vm.broker, "name", $$v)},expression:"broker.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Código","rules":"required|alpha_num|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Código *","placeholder":"Código","dense":"","maxlength":"25","error-messages":errors[0],"append-icon":"mdi-check-bold"},model:{value:(_vm.broker.code),callback:function ($$v) {_vm.$set(_vm.broker, "code", $$v)},expression:"broker.code"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Portal URL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Portal URL *","placeholder":"Portal URL *","dense":"","error-messages":errors[0],"append-icon":"mdi-web"},model:{value:(_vm.broker.portal),callback:function ($$v) {_vm.$set(_vm.broker, "portal", $$v)},expression:"broker.portal"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Correo de contacto","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo de contacto *","placeholder":"Correo de contacto","dense":"","error-messages":errors[0],"append-icon":"mdi-email"},model:{value:(_vm.broker.contact_email),callback:function ($$v) {_vm.$set(_vm.broker, "contact_email", $$v)},expression:"broker.contact_email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono principal","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-input',{attrs:{"label":"Teléfono principal","placeholder":"Teléfono principal","dense":"","error-messages":errors[0],"append-icon":"mdi-phone"},model:{value:(_vm.broker.main_phone),callback:function ($$v) {_vm.$set(_vm.broker, "main_phone", $$v)},expression:"broker.main_phone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono secundario","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-input',{attrs:{"label":"Teléfono secundario","placeholder":"Teléfono secundario","dense":"","error-messages":errors[0],"append-icon":"mdi-phone"},model:{value:(_vm.broker.secondary_phone),callback:function ($$v) {_vm.$set(_vm.broker, "secondary_phone", $$v)},expression:"broker.secondary_phone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Dirección","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Dirección","placeholder":"Dirección","dense":"","error-messages":errors[0],"append-icon":"mdi-map-marker"},model:{value:(_vm.broker.address),callback:function ($$v) {_vm.$set(_vm.broker, "address", $$v)},expression:"broker.address"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Facebook","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Facebook","placeholder":"Facebook","maxlength":"50","dense":"","error-messages":errors[0],"append-icon":"mdi-facebook"},model:{value:(_vm.broker.facebook),callback:function ($$v) {_vm.$set(_vm.broker, "facebook", $$v)},expression:"broker.facebook"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Twitter","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Twitter","placeholder":"Twitter","maxlength":"50","dense":"","error-messages":errors[0],"append-icon":"mdi-twitter"},model:{value:(_vm.broker.twitter),callback:function ($$v) {_vm.$set(_vm.broker, "twitter", $$v)},expression:"broker.twitter"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Instagram","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Instagram","placeholder":"Instagram","maxlength":"50","dense":"","error-messages":errors[0],"append-icon":"mdi-instagram"},model:{value:(_vm.broker.instagram),callback:function ($$v) {_vm.$set(_vm.broker, "instagram", $$v)},expression:"broker.instagram"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Datos gráficos")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Logo desktop del broker","rules":{
            required: _vm.broker.img_desktop ? false : true ,
            ext: 'png',
            size: 300,
            max_dimensions: [300,60]
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-file-input',{staticClass:"pt-3",attrs:{"rules":_vm.rules,"accept":_vm.fileType,"dense":"","show-size":"","outlined":"","label":"Logo desktop del broker","error-messages":errors[0]},on:{"change":function($event){return _vm.handleFile($event, 'desktop')},"click:clear":function($event){return _vm.clearInputFile('desktop')}},model:{value:(_vm.broker.desktop_file),callback:function ($$v) {_vm.$set(_vm.broker, "desktop_file", $$v)},expression:"broker.desktop_file"}})]}}])})],1),(_vm.broker.img_desktop)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-label',[_vm._v("Logo")]),_c('img',{staticClass:"broker-img ml-5 blue-grey lighten-4",attrs:{"src":("data:image/png;base64," + (_vm.broker.img_desktop)),"alt":("Logo desktop " + (_vm.broker.name))}})],1)]):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Logo mobile del broker","rules":{
            required: _vm.broker.img_mobile ? false : true ,
            ext: 'png',
            size: 300,
            max_dimensions: [300,60]
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-file-input',{staticClass:"pt-3",attrs:{"rules":_vm.rules,"accept":_vm.fileType,"dense":"","show-size":"","outlined":"","label":"Logo mobile del broker","error-messages":errors[0]},on:{"change":function($event){return _vm.handleFile($event, 'mobile')},"click:clear":function($event){return _vm.clearInputFile('mobile')}},model:{value:(_vm.broker.mobile_file),callback:function ($$v) {_vm.$set(_vm.broker, "mobile_file", $$v)},expression:"broker.mobile_file"}})]}}])})],1),(_vm.broker.img_mobile)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-label',[_vm._v("Logo")]),_c('img',{staticClass:"broker-img ml-5 blue-grey lighten-4",attrs:{"src":("data:image/png;base64," + (_vm.broker.img_mobile)),"alt":("Logo mobile " + (_vm.broker.name))}})],1)]):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Background del broker","rules":{
            required: _vm.broker.img_background ? false : true ,
            ext: ['png','jpg'],
            size: 300,
            max_dimensions: [960,1080]
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-file-input',{staticClass:"pt-3",attrs:{"rules":_vm.rules,"accept":".jpg,.png","dense":"","show-size":"","outlined":"","label":"Background del broker","error-messages":errors[0]},on:{"change":function($event){return _vm.handleFile($event, 'background')},"click:clear":function($event){return _vm.clearInputFile('background')}},model:{value:(_vm.broker.background_file),callback:function ($$v) {_vm.$set(_vm.broker, "background_file", $$v)},expression:"broker.background_file"}})]}}])})],1),(_vm.broker.img_background)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-label',[_vm._v("Background")]),_c('img',{staticClass:"broker-img ml-5 blue-grey lighten-4",attrs:{"src":("" + (_vm.broker.img_background)),"alt":("Background " + (_vm.broker.name))}})],1)]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-label',[_vm._v("Color del tema")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"ma-0 pa-0",attrs:{"hide-details":"","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({style:(_vm.swatchStyle)},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('ValidationProvider',{attrs:{"name":"Color del tema","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-color-picker',{attrs:{"show-swatches":"","swatches-max-height":"80","flat":"","error-messages":errors[0],"mode":"hexa"},model:{value:(_vm.broker.color),callback:function ($$v) {_vm.$set(_vm.broker, "color", $$v)},expression:"broker.color"}})]}}])})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.broker.color),callback:function ($$v) {_vm.$set(_vm.broker, "color", $$v)},expression:"broker.color"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Datos de configuración SMTP")]),_c('span',{staticClass:"font-italic ml-3"},[_vm._v("* Campos requeridos")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Host","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Host *","placeholder":"Host","dense":"","error-messages":errors[0],"append-icon":"mdi-server-network"},model:{value:(_vm.broker.smtp.host),callback:function ($$v) {_vm.$set(_vm.broker.smtp, "host", $$v)},expression:"broker.smtp.host"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Port","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('numeric-input',{attrs:{"label":"Port *","placeholder":"Port","dense":"","error-messages":errors[0],"append-icon":"mdi-connection","options":{
            maximumValue: 9999999999,
            decimalPlaces: 0,
            digitGroupSeparator: ''
          }},model:{value:(_vm.broker.smtp.port),callback:function ($$v) {_vm.$set(_vm.broker.smtp, "port", _vm._n($$v))},expression:"broker.smtp.port"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Username *","placeholder":"Username","dense":"","error-messages":errors[0],"append-icon":"mdi-account-box"},model:{value:(_vm.broker.smtp.username),callback:function ($$v) {_vm.$set(_vm.broker.smtp, "username", $$v)},expression:"broker.smtp.username"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Password","rules":{
          required: _vm.broker.id ? false : true
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.broker.id ? 'Password' : 'Password *',"placeholder":"Password","dense":"","error-messages":errors[0],"append-icon":"mdi-lock"},model:{value:(_vm.broker.smtp.password),callback:function ($$v) {_vm.$set(_vm.broker.smtp, "password", $$v)},expression:"broker.smtp.password"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Tipo de encriptación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"label":"Tipo de encriptación *","placeholder":"Tipo de encriptación","dense":"","error-messages":errors[0],"hide-details":"","single-line":"","light":""},model:{value:(_vm.broker.smtp.encryption),callback:function ($$v) {_vm.$set(_vm.broker.smtp, "encryption", $$v)},expression:"broker.smtp.encryption"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>      
  <div>
    <v-row>
      <v-col cols="12">
        <span class="font-weight-bold">Datos generales</span>
        <span class="font-italic ml-3">* Campos requeridos</span>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Nombre"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Nombre *"
            placeholder="Nombre"
            v-model="broker.name"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-account-tie"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Código"
          rules="required|alpha_num|max:25"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Código *"
            placeholder="Código"
            v-model="broker.code"
            dense
            maxlength="25"
            :error-messages="errors[0]"
            append-icon="mdi-check-bold"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Portal URL"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Portal URL *"
            placeholder="Portal URL *"
            v-model="broker.portal"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-web"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Correo de contacto"
          rules="email|required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Correo de contacto *"
            placeholder="Correo de contacto"
            v-model="broker.contact_email"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-email"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Teléfono principal"
          rules="required|min:6"
          v-slot="{ errors }"
        >
          <phone-input
            label="Teléfono principal"
            placeholder="Teléfono principal"
            v-model="broker.main_phone"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-phone"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Teléfono secundario"
          rules=""
          v-slot="{ errors }"
        >
          <phone-input
            label="Teléfono secundario"
            placeholder="Teléfono secundario"
            v-model="broker.secondary_phone"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-phone"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Dirección"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            label="Dirección"
            placeholder="Dirección"
            v-model="broker.address"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-map-marker"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Facebook"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            label="Facebook"
            placeholder="Facebook"
            v-model="broker.facebook"
            maxlength="50"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-facebook"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Twitter"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            label="Twitter"
            placeholder="Twitter"
            v-model="broker.twitter"
            maxlength="50"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-twitter"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Instagram"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            label="Instagram"
            placeholder="Instagram"
            v-model="broker.instagram"
            maxlength="50"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-instagram"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12">
        <span class="font-weight-bold">Datos gráficos</span>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
            name="Logo desktop del broker"
            :rules="{
              required: broker.img_desktop ? false : true ,
              ext: 'png',
              size: 300,
              max_dimensions: [300,60]
            }"
            v-slot="{ errors }"
          >
            <v-file-input
              v-model="broker.desktop_file"
              :rules="rules"
              :accept="fileType"
              dense
              show-size
              outlined
              class="pt-3"
              label="Logo desktop del broker"
              @change="handleFile($event, 'desktop')"
              @click:clear="clearInputFile('desktop')"
              :error-messages="errors[0]"
            ></v-file-input>
          </ValidationProvider>
      </v-col>
      <v-col v-if="broker.img_desktop" cols="12" md="6">
        <div class="d-flex align-center" >
          <v-label >Logo</v-label>
          <img class="broker-img ml-5 blue-grey lighten-4" :src="`data:image/png;base64,${broker.img_desktop}`" :alt="`Logo desktop ${broker.name}`">
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
            name="Logo mobile del broker"
            :rules="{
              required: broker.img_mobile ? false : true ,
              ext: 'png',
              size: 300,
              max_dimensions: [300,60]
            }"
            v-slot="{ errors }"
          >
            <v-file-input
              v-model="broker.mobile_file"
              :rules="rules"
              :accept="fileType"
              dense
              show-size
              outlined
              class="pt-3"
              label="Logo mobile del broker"
              @change="handleFile($event, 'mobile')"
              @click:clear="clearInputFile('mobile')"
              :error-messages="errors[0]"
            ></v-file-input>
          </ValidationProvider>
      </v-col>
      <v-col v-if="broker.img_mobile" cols="12" md="6">
        <div class="d-flex align-center">
          <v-label >Logo</v-label>
          <img class="broker-img ml-5 blue-grey lighten-4" :src="`data:image/png;base64,${broker.img_mobile}`" :alt="`Logo mobile ${broker.name}`">
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <ValidationProvider
            name="Background del broker"
            :rules="{
              required: broker.img_background ? false : true ,
              ext: ['png','jpg'],
              size: 300,
              max_dimensions: [960,1080]
            }"
            v-slot="{ errors }"
          >
            <v-file-input
              v-model="broker.background_file"
              :rules="rules"
              accept=".jpg,.png"
              dense
              show-size
              outlined
              class="pt-3"
              label="Background del broker"
              @change="handleFile($event, 'background')"
              @click:clear="clearInputFile('background')"
              :error-messages="errors[0]"
            ></v-file-input>
          </ValidationProvider>
      </v-col>
      <v-col v-if="broker.img_background" cols="12" md="6">
        <div class="d-flex align-center">
          <v-label >Background</v-label>
          <img class="broker-img ml-5 blue-grey lighten-4" :src="`${broker.img_background}`" :alt="`Background ${broker.name}`">
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-label>Color del tema</v-label>
        <v-text-field v-model="broker.color" v-mask="mask" hide-details class="ma-0 pa-0" solo>
          <template v-slot:append>
            <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div :style="swatchStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <ValidationProvider
                    name="Color del tema"
                    rules="required"
                    v-slot="{ errors }"
                  >
                  <v-color-picker 
                    v-model="broker.color" 
                    show-swatches  
                    swatches-max-height="80" 
                    flat 
                    :error-messages="errors[0]"
                    mode="hexa"
                  />
                  </ValidationProvider>
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PhoneInput from '@/components/commons/PhoneInput'

export default {
  components: { PhoneInput },
  props: {
    broker: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      mask: '!#XXXXXX',
      menu: false,
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
      ],
      rules: [
        value =>
          !value ||
          value.size < 300000 ||
          `El archivo debe pesar menos de 300KB`
      ],
      fileType: '.png',
      fileType2: '.png,.jpg'
    }
  },
  computed: {
    color() {
      return this.broker.color
    },
    swatchStyle() {
      const { color, menu } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
  methods: {
    clearInputFile(type) {
      const index = this.broker.files.findIndex(
        (i) => i.type == type
      )
      this.broker.files.splice(index, 1)
    },
    handleFile(file, type) {
      if (file) {
        const fileSize = file.size
        const extension = `.${file.type.replace(/(.*)\//g, '')}`

        const fr = new FileReader()
        fr.readAsDataURL(file)
        fr.addEventListener('load', () => {
          const base64 = fr.result.split(',')[1]

          this.broker.files.push({
            extension: extension,
            nombre: file.name,
            file: base64,
            size: fileSize,
            type: type
          })
        })
      }
    },
  }
}
</script>
<style scoped>
.broker-img {
  width: 220px;
}
.divider {
  background-color: #2196F3FF;
}
</style>

<template>      
  <div>
    <v-row>
      <v-col cols="12">
        <span class="font-weight-bold">Datos de configuración SMTP</span>
        <span class="font-italic ml-3">* Campos requeridos</span>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Host"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Host *"
            placeholder="Host"
            v-model="broker.smtp.host"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-server-network"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Port"
          rules="required|numeric|min_value:1"
          v-slot="{ errors }"
        >
          <numeric-input
            label="Port *"
            placeholder="Port"
            v-model.number="broker.smtp.port"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-connection"
            :options="{
              maximumValue: 9999999999,
              decimalPlaces: 0,
              digitGroupSeparator: ''
            }"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Username"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Username *"
            placeholder="Username"
            v-model="broker.smtp.username"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-account-box"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Password"
          :rules="{
            required: broker.id ? false : true
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="broker.id ? 'Password' : 'Password *'"
            placeholder="Password"
            v-model="broker.smtp.password"
            dense
            :error-messages="errors[0]"
            append-icon="mdi-lock"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="Tipo de encriptación"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            :items="items"
            label="Tipo de encriptación *"
            placeholder="Tipo de encriptación"
            v-model="broker.smtp.encryption"
            dense
            :error-messages="errors[0]"
            hide-details
            single-line
            light
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NumericInput from '@/components/commons/NumericInput'
export default {
  components: { NumericInput },
  props: {
    broker: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      items: [
        {text: 'Ninguno', value: null},
        {text: 'SSL', value: 'ssl'},
        {text: 'TLS', value: 'tls'}
      ]
    }
  },
  methods: {
    
  }
}
</script>


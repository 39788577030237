<template>
  <BaseList
    color="primary"
    :title="$route.meta.title"
    :section="$route.meta.section"
    :headers="headers"
    :items="brokers"
    :loading="loading"
    :totalRows="totalRows"
    addText="Agregar Broker"
    @add="handleNew"
    @edit="handleEdit"
    @refresh="getData"
    @delete="handleDelete"
    @search="handleSearch"
    @options="handleOptions"
  >
  </BaseList>
</template>
<script>
import BaseList from '@/components/commons/BaseList'
import BrokerService from '@/services/broker'

export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      loading: false,
      brokers: [],
      totalRows: 0,
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Portal de Acceso', value: 'portal' },
      ]
    }
  },
  methods: {
    async getData() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.options

      this.loading = true
      try {
        const { data } = await BrokerService.fetch(
          { 'filter[query]': this.search },
          sortBy[0] ? sortBy[0] : '',
          sortDesc[0],
          page,
          itemsPerPage
        )
        this.brokers = data.data
        this.totalRows = data.total
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },

    handleNew() {
      this.$router.push({ name: 'BrokerCreate' })
    },

    handleEdit(item) {
      this.$router.push({
        name: 'BrokerEdit',
        params: { id: item.id }
      })
    },

    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text:
          '¿ Desea eliminar el broker ?'
      })

      if (!res) return false

      try {
        await BrokerService.delete(item.id)
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error('No se ha podido eliminar el broker.')
      }
    },

    handleOptions(options) {
      this.options = options
      this.getData()
    },

    handleSearch(value) {
      this.search = value
      this.getData()
    },
  }
}
</script>

<style></style>

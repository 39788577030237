<template>
<div>
  <v-alert
    border="right"
    colored-border
    type="info"
    elevation="2"
    v-if="showTab"
  >
    <div class="font-weight-bold" v-for="message in messages" :key="message">
      <span>{{ message }}</span>
    </div> 
  </v-alert>
  <BaseForm
    :title="$route.meta.title"
    :loading="loading"
    @submit="handleSubmit"
    :submitDisabled="submitDisabled"
  >
    <template v-slot:content>
      <v-tabs v-model="tab" active-class="tab-active">
        <v-tab>
          General
        </v-tab>
        <v-tab>
          SMTP
        </v-tab>

        <v-tab-item>
          <general class="my-6" :broker="broker" />
        </v-tab-item>
        <v-tab-item>
          <smtp class="my-6" :broker="broker" />
        </v-tab-item>
      </v-tabs>
    </template>
  </BaseForm>
</div>
  
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import BrokerService from '@/services/broker'
import General from './partials/General.vue'
import Smtp from './partials/Smtp.vue'

export default {
  components: { BaseForm, General, Smtp },
  data() {
    return {
      tab: null,
      loading: false,
      broker: {
        color: '#00559E', 
        files: [], 
        smtp: {
          host: '',
          port: '',
          username: '',
          password: '',
          encryption: ''
        }
      },
    }
  },
  computed: {
    messages() {
      return this.tab == 0 
        ? [
            'El logo del broker debe estar en formato .png, no debe pesar más de 300kb y sus dimensiones máximas son 300px de ancho x 60px de alto.', 
            'El background del broker debe estar en formato .png o .jpg, no debe pesar mas de 300kb y sus dimensiones máximas son 980px de ancho x 1080px de alto.'
          ] 
        : ['Si no desea actualizar el password en la configuración SMTP deje el campo vacío.']
    },
    showTab() {
      return this.broker.id || (!this.broker.id && this.tab == 0)
    },
    submitDisabled() {
      return this.broker.smtp.host == ''
    }
  },
  async created() {
    this.loading = true
    try {
      const id = this.$route.params.id
      if (id) {
        const data = await BrokerService.find(id, {
          include: 'smtp'
        })
        this.broker = {...data, files: []}
      }
    } catch (error) {
      console.log(error)
      this.$dialog.message.error('No se pudo cargar el registro.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.broker.smtp.password == '') delete this.broker.smtp.password
        await BrokerService.save(this.broker)
        this.$dialog.notify.success('Registro guardado con éxito.')
        this.$router.go(-1)
      } catch {
        this.$dialog.message.error('No se pudo guardar el registro.')
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
.broker-img {
  width: 220px;
}
.divider {
  background-color: #2196F3FF;
}
</style>
